import React from 'react';
import PropTypes from 'prop-types';
// import StoryblokComponents from '../storyblok/components';
import BlogDetails from '../components/blog-details';

// **
// * This page grabs HomeTwo which handles rendering the page content
// * You probably don't need to change this page
// **

const PageTemplate = ({ pageContext }) => {
  const { story } = pageContext;

  return (
    <BlogDetails
      name={story.name}
      published={story.published_at}
      content={JSON.parse(story.content)}
    />
  );
};

export default PageTemplate;

//

PageTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
};
